@import '../../../styles/const.less';

.explore-container {
    padding: 60px 40px;
    position: relative;
    .title {
        color: @primary-color;
        font-size: 36px;
        font-weight: 500;
    }
    .desc {
        display: block;
        margin-bottom: 40px;
        font-size: 14px;
        font-weight: 400;
        color: @secondary-color;
    }
    .select {
        z-index: 10;

        position: absolute;
        right: 40px;
    }
    .load {
        margin: 40px auto 0;
    }
    //global antd styles
    :global {
        .ant-tabs-tab {
            font-size: 16px;
            line-height: 16px;
            .ant-tabs-tab-btn {
                color: @secondary-color;
            }
        }
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: @primary-color;
            }
        }
        .ant-tabs-ink-bar {
            background-color: @primary-color;
        }
        .ant-tabs-nav::before {
            border-bottom-color: @mini-color;
        }
    }
}
@media (max-width: 600px) {
    .explore-container {
        padding: 8vw 5.3333vw;

        .desc {
            margin-bottom: 10.6667vw;
        }
        .select {
            z-index: 10;
            position: static;
            margin-bottom: 40px;
        }
    }
}
