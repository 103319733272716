.empty-item{
    width:100%;
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:94px;
    .logo{
       width:100px;
       height:80px;
       margin-bottom:30px;
       img{
        width:100px;
        height:80px;
       }
    }
    .word{
        font-style: normal;
        font-weight: 500;
        font-size: 24px; 
        @media screen and (max-width: 800px) {
            font-size: 16px; 
        }
    }
}