@import '../../../../../styles/const.less';

.h5-container {
    display: none;
}

.license-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid @mini-color;
    .index {
        font-size: 16px;
        color: @primary-color;
    }
    .infos {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
    .info {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 25px;
    }
    .icon-container {
        position: relative;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        .icon {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        .verify {
            position: absolute;
            bottom: 0;
            right: -2px;
            width: 16px;
            height: 16px;
        }
    }
    .name-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        min-width: 330px;
        max-width: 440px;
        .name {
            .index();
        }
        .collection {
            font-size: 13px;
            color: @secondary-color;
        }
    }
}

@media (max-width: 600px) {
    .pc-container {
        display: none;
    }
    .h5-container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 5.3333vw;
        border-bottom: 1px solid @mini-color;
    }
    .license-item {
        padding: 5.3333vw 0;
        border-bottom: 0;

        .info {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 4.5333vw;
        }
        .icon-container {
            position: relative;
            width: 9.0667vw;
            height: 9.0667vw;

            .verify {
                width: 4.2667vw;
                height: 4.2667vw;
            }
        }
        .name-container {
            margin-left: 2.6667vw;
            width: 54.6667vw;
            min-width: 25.6667vw;
            // max-width: 42.6667vw;
        }
        .name {
            max-width: 42.6667vw;
            white-space: nowrap;
        }
    }
    .infos {
        flex: 1;
        margin-left: 7.4667vw;
    }
    .arrow {
        z-index: 10;
        position: absolute;
        top: 28vw;
        right: 2.6667vw;
        height: 2.6667vw;
        width: 3.7333vw;
    }
    .active {
        transform: rotate(180deg);
    }
    .inactive {
        transform: rotate(0);
    }
}
